var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
import { StyleForErrorModalMaterial } from './styleForErrorModal';
import { setOpenErrorMaterialModal } from '../../../redux/authReducer';
import { Button } from '@mui/material';
var ModalErrorMaterialBlock = function (props) {
    var isOpen = props.isOpen, Text = props.Text;
    var dispatch = useAppDispatch();
    var closeModal = function () {
        return (dispatch(setOpenErrorMaterialModal({ active: false })));
    };
    return (_jsx(Modal, __assign({ open: isOpen, onClose: function () { closeModal(); }, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsxs(Box, __assign({ sx: StyleForErrorModalMaterial }, { children: [_jsx(Typography, __assign({ id: "modal-modal-title", variant: "h6", component: "h2" }, { children: Text })), _jsx(Button, __assign({ onClick: function () { closeModal(); }, style: { width: 215, height: 44,
                        backgroundColor: "rgb(20, 130, 197,0.6)",
                        color: 'f9f9f9',
                        borderRadius: 8,
                        marginTop: '5%' }, variant: "contained" }, { children: " \u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C" }))] })) })));
};
var mapDispatchErrorMatterialModal = function (state) {
    return {
        isOpen: state.auth.openErrorMaterialModal,
        Text: state.auth.errorTextModalMaterial
    };
};
export var ModalErrorMaterial = connect(mapDispatchErrorMatterialModal)(ModalErrorMaterialBlock);
