var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EditUserFormMobile } from "../../Helpers/Forms/EditUserForMobile/EditUserForMobile";
import chevron_left from "../../../assets/img/chevron_left_icon.png";
import close_icon from "../../../assets/img/close_btn_on_modal.png";
import style from "./modalEditingUserMobile.module.css";
import { useAppDispatch } from "../../../redux/store";
import { setActive } from "../../../redux/modalReducer";
export var ModalEditingUserMobile = function (_a) {
    var isActive = _a.isActive, key = _a.key;
    var dispatch = useAppDispatch();
    var handleCloseModal = function () {
        dispatch(setActive(false));
    };
    return (isActive && (_jsxs("div", __assign({ className: style.ModalEditingUserMobile }, { children: [_jsxs("div", __assign({ className: style.closeModal }, { children: [_jsx("img", { onClick: function () { return handleCloseModal(); }, className: style.closeIcon, src: chevron_left, alt: "chev left" }), _jsx("img", { onClick: function () { return handleCloseModal(); }, className: style.closeIcon, src: close_icon, alt: "close" })] })), _jsx(EditUserFormMobile, {})] }))));
};
