// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.haVKaUTChrBhyDKGwT0t {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    opacity: 1;
    z-index: 100;
    padding: 16px 10px;
    position: fixed;
    margin-top: 60px;
    top: 0;
    left: 0;

}

.AtomcWPzkqf8QPG3Cq3z {
    width: 100%;
    height: 100%;
}

.dVcHOLJE5XZQbiqOVtya {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    margin-left: 87%;
    margin-top: 10px;
}

@media (max-width: 880px) {
    .haVKaUTChrBhyDKGwT0t {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        opacity: 1;
        z-index: 100;
        margin-top: 60px;
        padding: 16px 0px;
    }

    .dVcHOLJE5XZQbiqOVtya {
        margin-left: 85%;

    }

}

@media (max-width: 480px) {
    .dVcHOLJE5XZQbiqOVtya {
        margin-left: 83%;

    }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/modalInCompanySearchCompanieLittleBlock/modalInCompanySearchCompanieLittleBlock.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,MAAM;IACN,OAAO;;AAEX;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,8BAA8B;QAC9B,mBAAmB;QACnB,WAAW;QACX,aAAa;QACb,sBAAsB;QACtB,UAAU;QACV,YAAY;QACZ,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,gBAAgB;;IAEpB;;AAEJ;;AAEA;IACI;QACI,gBAAgB;;IAEpB;AACJ","sourcesContent":[".all_modal_search_company {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    width: 100%;\r\n    height: 100vh;\r\n    background-color: #fff;\r\n    opacity: 1;\r\n    z-index: 100;\r\n    padding: 16px 10px;\r\n    position: fixed;\r\n    margin-top: 60px;\r\n    top: 0;\r\n    left: 0;\r\n\r\n}\r\n\r\n.filter_content {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.btnClose {\r\n    background: none;\r\n    border: none;\r\n    cursor: pointer;\r\n    position: absolute;\r\n    margin-left: 87%;\r\n    margin-top: 10px;\r\n}\r\n\r\n@media (max-width: 880px) {\r\n    .all_modal_search_company {\r\n        display: flex;\r\n        flex-direction: column;\r\n        justify-content: space-between;\r\n        align-items: center;\r\n        width: 100%;\r\n        height: 100vh;\r\n        background-color: #fff;\r\n        opacity: 1;\r\n        z-index: 100;\r\n        margin-top: 60px;\r\n        padding: 16px 0px;\r\n    }\r\n\r\n    .btnClose {\r\n        margin-left: 85%;\r\n\r\n    }\r\n\r\n}\r\n\r\n@media (max-width: 480px) {\r\n    .btnClose {\r\n        margin-left: 83%;\r\n\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"all_modal_search_company": `haVKaUTChrBhyDKGwT0t`,
	"filter_content": `AtomcWPzkqf8QPG3Cq3z`,
	"btnClose": `dVcHOLJE5XZQbiqOVtya`
};
export default ___CSS_LOADER_EXPORT___;
