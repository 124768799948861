var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { setActive } from "../../../redux/modalReducer";
import { useAppDispatch } from "../../../redux/store";
import style from "./modalInCompanySearchCompanieLittleBlock.module.css";
import SearchCompaniesLittleBlock from "../../SearchCompaniesLittleBlock/SearchCompaniesLittleBlock";
import CloseIcon from "@mui/icons-material/Close";
export var ModalInCompanySearchCompanieLittleBlock = function (_a) {
    var isActive = _a.isActive, key = _a.key;
    var dispatch = useAppDispatch();
    var handleCloseFilter = function () {
        dispatch(setActive(false));
    };
    return isActive ? (_jsxs("div", __assign({ className: style.all_modal_search_company }, { children: [_jsx("button", __assign({ className: style.btnClose, onClick: function () { return handleCloseFilter(); } }, { children: _jsx(CloseIcon, { fontSize: "large", sx: { width: "24px", height: "24px" } }) })), _jsx("div", __assign({ className: style.filter_content }, { children: _jsx(SearchCompaniesLittleBlock, {}) }))] }))) : null;
};
