var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from "./modalFilterAndSearch.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import SearchCompaniesLittleBlock from "../../SearchCompaniesLittleBlock/SearchCompaniesLittleBlock";
import { setActive } from "../../../redux/modalReducer";
import { useAppDispatch } from "../../../redux/store";
import { VacanciesFilterForMobile } from "../../VacanciesFillter/VacancyFilterForMobile";
import { setCheckedFilterToDefault, setVacancyFilterParameters } from "../../../redux/vacanciesReduser";
export var ModalFilterAndSearch = function (_a) {
    var isActive = _a.isActive, key = _a.key;
    var _b = useState(false), openFilter = _b[0], setOpenFilter = _b[1];
    var dispatch = useAppDispatch();
    var handleCloseFilter = function () {
        dispatch(setCheckedFilterToDefault());
        dispatch(setVacancyFilterParameters({}));
        setOpenFilter(false);
        dispatch(setActive(false));
    };
    return isActive ? (_jsx("div", __assign({ onClick: function (e) { return e.stopPropagation(); }, className: style.ModalOpenSubscribesCard }, { children: _jsxs("div", __assign({ className: style.containerModal }, { children: [_jsxs("div", __assign({ className: style.all_buttons }, { children: [_jsxs("div", __assign({ className: style.button_Toggle_and_Close }, { children: [_jsx("button", __assign({ className: openFilter === false ? style.btnToggle_active : style.btnToggle, onClick: function () {
                                        setOpenFilter(false);
                                    } }, { children: "\u0424\u0438\u043B\u044C\u0442\u0440 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0439" })), _jsx("button", __assign({ className: openFilter === true ? style.btnToggle_active : style.btnToggle, onClick: function () {
                                        setOpenFilter(true);
                                        dispatch(setCheckedFilterToDefault());
                                        dispatch(setVacancyFilterParameters({}));
                                    } }, { children: "\u041A\u043E\u043C\u043F\u0430\u043D\u0438\u0438" }))] })), _jsx("div", __assign({ className: style.block_buttonClose }, { children: _jsx("button", __assign({ className: style.buttonClose, onClick: function () { return handleCloseFilter(); } }, { children: _jsx(CloseIcon, { fontSize: "large" }) })) }))] })), openFilter === false && _jsx(VacanciesFilterForMobile, {}), openFilter === true && _jsx(SearchCompaniesLittleBlock, { withVacancies: true })] })) }))) : null;
};
